// Primary Hero
// Used for top of most pages

import Image from 'next/image'
import { forwardRef } from 'react'
import type { MutableRefObject } from 'react'
import { placeholderProps } from 'utils/client'
import { HorizontalCard, HeaderContent } from 'ui'
import { ImageJsonLd } from 'next-seo'
import type { PageDocument } from 'types/types.generated'

const PrimaryHero = forwardRef((props: PageDocument, ref: MutableRefObject<HTMLDivElement>) => {
	const { primary, items, globalData } = props || {}
	const {
		hero_heading,
		hero_description,
		section_primary_cta_label,
		section_primary_cta_link,
		section_secondary_cta_label,
		section_secondary_cta_link,
		background_image,
		text_contrast_mode,
		centered
	} = primary || {}

	return (
		<header
			className={`
				primary-hero 
				container pb-6 xl:pb-28 
				mt-[--nav-height]
				${text_contrast_mode === 'light' ? 'text-white' : 'text-black-rt'}
			`}
			ref={ref}
		>
			<div className="relative flex max-lg:flex-col gap-6 lg:flex-row xl:flex-col justify-between p-6 md:p-16 lg:p-24 xl:p-32 rounded-lg bg-gray-500 ">
				{background_image?.url ? (
					<>
						<Image
							src={`${background_image.url}&w=2400&h=594&fit=crop`}
							alt={background_image.alt || 'background image'}
							fill
							className="rounded-lg object-cover"
							sizes="(max-width: 640px) 100vw, 1600px"
							priority
							{...placeholderProps(background_image)}
						/>
						<ImageJsonLd
							contentUrl={background_image.url}
							images={[background_image]}
							creator="Real Thread"
							creditText="Real Thread"
						/>
					</>
				) : null}

				{/* Title, description, CTAs */}
				<HeaderContent
					title={hero_heading}
					titleFontSize="desktop-h1"
					headerAs="h1"
					description={hero_description}
					primaryCta={{ label: section_primary_cta_label, link: section_primary_cta_link }}
					secondaryCta={{ label: section_secondary_cta_label, link: section_secondary_cta_link }}
					centered={centered}
					globalData={globalData}
				/>
				{/*Grid of cards*/}
				{items && Array.isArray(items) ? (
					<div
						className={`max-xl:w-full grid grid-cols-1 ${
							items.length === 1 ? '' : items.length === 2 ? 'lg:grid-cols-2' : 'xl:grid-cols-3'
						} relative z-10 gap-4 xl:gap-8 justify-center xl:absolute xl:inset-x-24 xl:-bottom-16`}
					>
						{items.map((card, i) => {
							return (
								<HorizontalCard
									className="bg-gray-100"
									key={i}
									resourceObj={{
										background_color: card.card_background_color,
										text_contrast: card.card_text_contrast_mode,
										...card
									}}
								/>
							)
						})}
					</div>
				) : null}
			</div>
		</header>
	)
})
PrimaryHero.displayName = 'PrimaryHero'

export default PrimaryHero
