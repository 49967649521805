import { useMemo, forwardRef } from 'react'
import dynamic from 'next/dynamic'

// Eagerly load critical above-the-fold components
import PrimaryHero from '@/components/slices/hero/PrimaryHero'
import BreadcrumbHero from '@/components/slices/hero/BreadcrumbHero'
import FeaturedPost from '@/components/slices/FeaturedPost'
import ImageScroll from '@/components/slices/body/ImageScroll'
import type { PageDocument, GlobalDocument } from 'types/types.generated'

// Group related components for better code splitting
const ProductComponents = {
	ProductSlider: dynamic(() => import('./body/ProductSlider'), { ssr: false }),
	ProductGrid: dynamic(() => import('./body/ProductGrid'), { ssr: false }),
	ProductSlice: dynamic(() => import('./body/ProductSlice'), { ssr: false })
}

const ImageComponents = {
	ImageGalleryGrid: dynamic(() => import('./body/ImageGalleryGrid')),
	XLImageGallery: dynamic(() => import('./body/XLImageGallery')),
	FullWidthImage: dynamic(() => import('./body/FullWidthImage'))
}

const ContentComponents = {
	FiftyFifty: dynamic(() => import('./body/FiftyFifty')),
	FiftyFiftyGroup: dynamic(() => import('./body/FiftyFiftyGroup')),
	RichTextSlice: dynamic(() => import('./body/RichTextSlice')),
	HeaderBlock: dynamic(() => import('./body/HeaderBlock')),
	IconRow: dynamic(() => import('./body/IconRow')),
	Accordions: dynamic(() => import('./body/Accordions')),
	CodeSlice: dynamic(() => import('./body/CodeSlice')),
	Table: dynamic(() => import('./body/Table'))
}

const MarketingComponents = {
	CategoryCarousel: dynamic(() => import('./body/CategoryCarousel')),
	CalloutCards: dynamic(() => import('./body/CalloutCards')),
	ArchitecturalCards: dynamic(() => import('./body/ArchitecturalCards')),
	ImageCardGrid: dynamic(() => import('./body/ImageCardGrid')),
	LargeCallout: dynamic(() => import('./body/LargeCallout')),
	LogoParty: dynamic(() => import('./body/LogoParty')),
	MarketingCardBlock: dynamic(() => import('./body/MarketingCardBlock'))
}

const InteractiveComponents = {
	FormSlice: dynamic(() => import('./body/FormSlice')),
	ReviewCarousel: dynamic(() => import('ui').then((mod) => mod.ReviewCarousel)),
	ReviewsCTA: dynamic(() => import('./body/ReviewsCTA')),
	Testimonial: dynamic(() => import('./body/Testimonial')),
	VideoBlock: dynamic(() => import('./body/VideoBlock')),
	TabSliceGroup: dynamic(() => import('./body/TabSliceGroup')),
	SliceGroup: dynamic(() => import('./SliceGroup'))
}

// Utility components
const Anchor = dynamic(() => import('./body/Anchor'))

type SliceProps = {
	slice: { slice_type: string }
	globalData: GlobalDocument
	data?: PageDocument
}

export const Slice = forwardRef<HTMLDivElement, SliceProps>(({ slice, globalData, data }, ref) => {
	const sliceType = slice?.slice_type

	const SliceComponent = useMemo(() => {
		switch (sliceType) {
			// Hero Slices (eagerly loaded)
			case 'primary_hero':
				return PrimaryHero
			case 'breadcrumb_hero':
				return BreadcrumbHero
			case 'featured_post':
				return FeaturedPost
			case 'image_scroll':
				return ImageScroll

			// Product Components
			case 'product_slider':
				return ProductComponents.ProductSlider
			case 'product_grid':
				return ProductComponents.ProductGrid
			case 'product_slice':
				return ProductComponents.ProductSlice

			// Image Components
			case 'image_gallery_grid':
				return ImageComponents.ImageGalleryGrid
			case 'xl_image_gallery':
				return ImageComponents.XLImageGallery
			case 'full-width_image':
				return ImageComponents.FullWidthImage

			// Content Components
			case '50-50':
				return ContentComponents.FiftyFifty
			case '50-50_group':
				return ContentComponents.FiftyFiftyGroup
			case 'rich_text':
				return ContentComponents.RichTextSlice
			case 'header_block':
				return ContentComponents.HeaderBlock
			case 'icon_row':
				return ContentComponents.IconRow
			case 'accordions':
				return ContentComponents.Accordions
			case 'code_block':
				return ContentComponents.CodeSlice
			case 'code_slice':
				return ContentComponents.CodeSlice
			case 'table':
				return ContentComponents.Table

			// Marketing Components
			case 'category_carousel':
				return MarketingComponents.CategoryCarousel
			case 'callout_cards':
				return MarketingComponents.CalloutCards
			case 'architectural_cards':
				return MarketingComponents.ArchitecturalCards
			case 'image_card_grid':
				return MarketingComponents.ImageCardGrid
			case 'large_callout':
				return MarketingComponents.LargeCallout
			case 'logo_party':
				return MarketingComponents.LogoParty
			case 'marketing_cards':
				return MarketingComponents.MarketingCardBlock

			// Interactive Components
			case 'form':
				return InteractiveComponents.FormSlice
			case 'review_carousel':
				return InteractiveComponents.ReviewCarousel
			case 'reviews_cta':
				return InteractiveComponents.ReviewsCTA
			case 'testimonial':
				return InteractiveComponents.Testimonial
			case 'video_block':
				return InteractiveComponents.VideoBlock
			case 'tab_slice_group':
				return InteractiveComponents.TabSliceGroup
			case 'slice_group':
				return InteractiveComponents.SliceGroup

			// Utility Components
			case 'anchor':
				return Anchor

			case 'announcement_bar':
				return null
			default:
				console.warn(
					sliceType ? `Missing component for slice of type: ${sliceType}` : 'No slice type defined!'
				)
				return null
		}
	}, [sliceType])

	// Only render component when it comes into view
	return SliceComponent ? (
		<SliceComponent {...slice} globalData={globalData} ref={ref} data={data} />
	) : null
})

Slice.displayName = 'Slice'

type AllSlicesProps = {
	page: PageDocument
	className?: string
}

export const AllSlices = ({ page, className }: AllSlicesProps) => {
	const { data, globalData } = page || {}
	const { hero, body } = data || {}

	return (
		<div className={className ?? ''}>
			{/* Hero Slices - Always render as they're above the fold */}
			{Array.isArray(hero) &&
				hero.map((slice, i) => <Slice slice={slice} key={i} globalData={globalData} data={data} />)}

			{/* Body Slices - Load progressively as they come into view */}
			{Array.isArray(body) ? (
				<>
					{body.map((slice, i) => (
						<Slice slice={slice} key={i} globalData={globalData} />
					))}
				</>
			) : null}
		</div>
	)
}
