import { MutableRefObject, forwardRef } from 'react'

import { PrismicLink } from 'ui'
import Label from 'ui/Label'
import type { PageDocument } from 'types/types.generated'

const FeaturedPost = forwardRef(
	(props: PageDocument, _ref: MutableRefObject<HTMLHeadingElement | HTMLParagraphElement>) => {
		const { primary, globalData, data } = props || {}
		const { label, featured_post_cta_label } = primary || {}
		const { featured_post } = data || {}

		return (
			<section className="featured-post container relative lg:pb-28 lg:-mt-20 xl:-mt-80">
				{featured_post ? (
					<div className="group relative lg:bg-white rounded-tl-lg flex flex-col gap-6 py-10 lg:p-20 max-w-[1028px] ml-auto z-30">
						<Label text={label ?? featured_post?.data.category?.uid} theme="dark" />

						<h2 className="desktop-h3 w-fit relative animated-underline animated-underline-lg group-hover:animate inline z-10">
							{featured_post?.data.title?.[0].text}
						</h2>
						<p className="text-g-500">{featured_post.data.summary[0].text}</p>

						<PrismicLink
							link={{
								url: `/${featured_post.data.category.uid}/${featured_post.uid}`
							}}
							label={featured_post_cta_label || 'Read More'}
							className="button button-dark button-md w-fit"
							withArrow
							globalData={globalData}
						/>
					</div>
				) : null}
			</section>
		)
	}
)
FeaturedPost.displayName = 'FeaturedPost'

export default FeaturedPost
