// Image Scroll (or "Tall Image Gallery" as called in Figma)

import Image from 'next/image'
import Carousel from 'ui/Carousel'
import { forwardRef, useMemo } from 'react'
import type { MutableRefObject } from 'react'
import { PlainText, DynamicBackground, HeaderContent } from 'ui'
import { placeholderProps } from 'utils/client'
import clsx from 'clsx'
import Link from 'next/link'
import { linkResolver } from 'utils'
import type { PageDocumentDataBodyImageScrollSlice } from 'types/types.generated'

const ImageScroll = forwardRef(
	(props: PageDocumentDataBodyImageScrollSlice, ref: MutableRefObject<HTMLDivElement>) => {
		const { items, globalData } = props || {}

		const {
			section_heading,
			heading_max_width,
			section_description,
			description_max_width,
			section_primary_cta_label,
			section_primary_cta_link,
			section_secondary_cta_label,
			section_secondary_cta_link,
			background_color,
			background_squeegee_option,
			background_height,
			text_contrast_mode,
			centered,
			image_display,
			prioritize
		} = props.primary || {}

		// Carousel logic
		const cards = useMemo(() => {
			return items?.map((imageObj, i) => {
				const { image, image_caption, image_title, link } = imageObj || {}

				return image?.url ? (
					linkResolver(link) !== '/' ? (
						<Link href={linkResolver(link)} className="group" key={i}>
							<Card
								idx={i}
								prioritize={prioritize}
								image_display={image_display}
								image={image}
								image_title={image_title}
								image_caption={image_caption}
							/>
						</Link>
					) : (
						<Card
							key={i}
							idx={i}
							prioritize={prioritize}
							image_display={image_display}
							image={image}
							image_title={image_title}
							image_caption={image_caption}
						/>
					)
				) : null
			})
		}, [image_display, items, prioritize])

		if (!items.some((imageObj) => imageObj?.image?.url)) return null

		return (
			<section
				className={clsx(
					(section_heading.length ||
						section_description.length ||
						section_primary_cta_label ||
						section_secondary_cta_label) &&
						'pt-20',
					'image-scroll max-lg:pt-20 relative section remove-space-if-in-rich-text'
				)}
				ref={ref}
			>
				<DynamicBackground
					background_color={background_color}
					backgroundHeight={background_height}
					backgroundSqueegeeOption={background_squeegee_option}
				/>

				<div className="container">
					<Carousel
						cards={cards}
						contentToLeftOfScrollButtons={
							// {/* Title, description, CTAs */}
							<HeaderContent
								title={section_heading}
								titleMaxWidth={heading_max_width}
								description={section_description}
								descriptionMaxWidth={description_max_width}
								primaryCta={{ label: section_primary_cta_label, link: section_primary_cta_link }}
								secondaryCta={{
									label: section_secondary_cta_label,
									link: section_secondary_cta_link
								}}
								textContrast={text_contrast_mode}
								centered={centered}
								globalData={globalData}
								className={!centered && '-mb-24'}
							/>
						}
					/>
				</div>
			</section>
		)
	}
)
ImageScroll.displayName = 'ImageScroll'

const Card = ({ image_display, image, image_title, image_caption, prioritize, idx }) => {
	return (
		<div className="flex flex-col">
			<div
				className={clsx('relative w-[300px] rounded-lg overflow-hidden mb-2', {
					'aspect-[3/4]': image_display === 'tall',
					'aspect-square': image_display === 'square'
				})}
			>
				<Image
					src={image.url}
					alt={image.alt || 'Image'}
					width={300}
					height={image_display === 'tall' ? 400 : 300}
					className="object-cover scale-image-animation w-full h-full"
					sizes="300px"
					quality={idx === 0 ? 75 : 60}
					priority={prioritize && idx === 0}
					{...placeholderProps(image)}
				/>
			</div>

			{image_title ? (
				<PlainText
					className="font-serif animated-underline group-hover:animate w-fit"
					content={image_title}
				/>
			) : null}
			{image_caption ? <PlainText className="text-xs" content={image_caption} /> : null}
		</div>
	)
}

export default ImageScroll
